import * as React from 'react';
import {connect} from 'react-redux';
// @ts-ignore
import {browserHistory} from 'react-router'
import {Nav} from 'react-bootstrap';
import './navpanel.css';
import { MainReducer } from "../../redux/reducers/root";

type NavPanelProps = {
    logout?: () => void,
    hideMenu: () => void,
}

const getReduxProps = (state: MainReducer) => {
    //console.log('NavPanel',state);
    return {
        pathname: state?.routing.locationBeforeTransitions?.pathname
    }
}

type Props = NavPanelProps & ReturnType<typeof getReduxProps>;

class NavPanel extends React.Component<Props> {

    handleSelect = (selectedKey: string) => {
        if (selectedKey === "LOG_OUT") {
            this.props.logout?.()
        } else {
            browserHistory.push(selectedKey)
            this.props.hideMenu();
        }
    };

    pathName = () => {
        //console.log('pathName', this.props.pathname, this.props.pathname.indexOf('/', 1), `|${this.props.pathname.substring(0, this.props.pathname.indexOf('/', 1))}|`)
        return (this.props.pathname || '/').substring(0, (this.props.pathname || '/').indexOf('/', 1))
    };

    render() {

        return (<Nav bsStyle='pills'
                     stacked
                     style={{height: '100%'}}
                     activeKey={this.pathName()}
                     // @ts-ignore
                     onSelect={this.handleSelect}>
                {/* <NavItem eventKey={'/overview'} title="Item">{english.dashboard}</NavItem> */}
            </Nav>
        )
    }
}

export default connect(getReduxProps)(NavPanel);
