import React, {} from 'react'
import {connect} from 'react-redux';
import Login from './login';
import { login, logout } from '../../redux/actions/user';
import { MainReducer } from "../../redux/reducers/root";

const getReduxProps = (state: MainReducer) => {
    //console.log('login > ', state.rest);
    return {
        loginFailed: !!state?.session?.loginFailed,
        user: state?.users?.user,
        loading: state?.rest?.loading,
        tokenLogin: state?.users.tokenLogin,
        resetPasswordState: state?.password?.resetPasswordState,
        error: state?.users?.authError
    }
}

type Props = {dispatch: any} & ReturnType<typeof getReduxProps>;

const LoginContainer: React.FC<Props> = (props) => {

    const doLogin = (user: string, password: string) => {
       //console.log("attempting login (" + user + ", " + password + ")");
       props.dispatch(login(user, password));
    }

    const doLogout = () => {
        props.dispatch(logout());
    }

    return <Login {...props} doLogout={doLogout} doLogin={doLogin} />

}

export default connect(getReduxProps)(LoginContainer);
