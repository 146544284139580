import * as Type from '../actions/rest'

export default function restReducer(state = {loading: false}, action: any) {

    switch (action.type) {

        case Type.REQUEST_PENDING:
            return {
                ...state,
                loading: true
            };
        case Type.RESPONSE_RECEIVED:
            return {
                ...state,
                loading: false
            };
        case Type.ERROR_RECEIVED:
            return {
                ...state,
                loading: false
            };
        case Type.CLEAR_REQUEST_STATE:
            return {
                ...state,
                loading: false
            };
        default:
            return state;

    }
}

