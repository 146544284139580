import React, {Component} from 'react'
import logo from '../resources/images/parkable-logo-white.png'

export default class WebsiteFooter extends Component {

    render(){
        return (
            <div id='footer'>

                <p className="footer-logo">
                    <img src={logo} alt='Parkable' width='100px'/>
                    &copy; 2022 Parkable. All rights reserved
                </p>
                <p className="terms-conditions">
                <a href='http://www.parkable.com/terms-of-service'>Terms and Conditions</a>
                <span> | </span>
                <a href='http://www.parkable.com/privacy-policy'>Privacy Policy</a>
                </p>

        </div>
		);
    }
}
