// @ts-ignore
import { UserAuthWrapper } from 'redux-auth-wrapper'
import { routerActions } from 'react-router-redux'
import { MainReducer } from "../redux/reducers/root";
import { UserRoleDTO } from "../api/userRole/dto/UserRole.dto";
import { Nully } from './nully';
import { isAdmin, isAuthorized, isInvestor, isNotAuthorized, isSuperInvestor, userNeedToAcceptTAndC } from './userRolesCheck';

type WrappedRoles = {
    userRoles: Nully<UserRoleDTO[]>
}

export const IsNotAuthorized = UserAuthWrapper({
    authSelector: (state: MainReducer) => ({userRoles: state?.users?.userRoles}),
    redirectAction: routerActions.replace,
    failureRedirectPath: '/documents',
    wrapperDisplayName: 'UserIsNotAuthorized',
    predicate: ({userRoles}: WrappedRoles) => {
        return isNotAuthorized(userRoles);
    },
    allowRedirectBack: false
})((props: any) => props.children);

export const IsAuthorized = UserAuthWrapper({
    authSelector: (state: MainReducer) => ({userRoles: state?.users?.userRoles}),
    redirectAction: routerActions.replace,
    failureRedirectPath: '/login',
    wrapperDisplayName: 'UserIsNotAuthorized',
    predicate: ({userRoles}: WrappedRoles) => {
        return isAuthorized(userRoles);
    },
    allowRedirectBack: false
})((props: any) => props.children);

export const IsUserNeedToAcceptTAndC = UserAuthWrapper({
    authSelector: (state: MainReducer) => ({userRoles: state?.users?.userRoles}),
    redirectAction: routerActions.replace,
    failureRedirectPath: '/',
    wrapperDisplayName: 'UserNeedToAcceptTAndC',
    predicate: ({userRoles}: WrappedRoles) => userNeedToAcceptTAndC(userRoles),
    allowRedirectBack: false
})((props: any) => props.children);

export const IsUserHasAccessToDataRoom = UserAuthWrapper({
    authSelector: (state: MainReducer) => ({userRoles: state?.users?.userRoles}),
    redirectAction: routerActions.replace,
    failureRedirectPath: '/termsAndConditions',
    wrapperDisplayName: 'UserHasAccessToDataRoom',
    predicate: ({userRoles}: WrappedRoles) => isInvestor(userRoles) || isAdmin(userRoles) || isSuperInvestor(userRoles),
    allowRedirectBack: false
})((props: any) => props.children);


