import "core-js/features/map";
import "core-js/features/set";
import 'es6-promise/auto';
import 'react-app-polyfill/ie9';
import React from 'react';
import { render } from 'react-dom';
import './index.css';
import App from './App';
import { hotjar } from 'react-hotjar';
import {environment} from './api/environment';

import { Provider } from 'react-redux'
import { createStore, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk';

//@ts-ignore
import { Router, Route, browserHistory, IndexRedirect } from 'react-router'
//@ts-ignore
import { syncHistoryWithStore, routerMiddleware } from 'react-router-redux'

import { IsAuthorized, IsNotAuthorized, IsUserHasAccessToDataRoom, IsUserNeedToAcceptTAndC } from './util/auth'
import {login} from  './redux/actions/user';
import rootReducer from './redux/reducers/root';

import LoginContainer from './components/login/loginContainer'
import GenericNotFound from './components/common/genericnotfound'
import Dataroom from './components/common/DataRoom'
import TermsAndConditions from './components/login/TermsAndConditions'

const routingMiddleware = routerMiddleware(browserHistory);
//@ts-ignore
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const store = createStore(
    rootReducer,
    composeEnhancers(
        applyMiddleware(thunk, routingMiddleware)
    )
);
// @ts-ignore
store.dispatch(login());
// @ts-ignore
window.store = store;

const history = syncHistoryWithStore(browserHistory, store);

if (environment() === 'production') {
    hotjar.initialize(1372541, 6);
}

render(

    <Provider
        // @ts-ignore
        store={store}>
        <Router
            // @ts-ignore
            onUpdate={() => window.scrollTo(0, 0)}
            history={history}>
            <Route path="/" component={App}>
                <Route component={IsAuthorized}>
                    <IndexRedirect to="/documents" />

                    <Route component={IsUserNeedToAcceptTAndC}>
                        <Route key='IsInvestorPending' path='termsAndConditions' component={TermsAndConditions} />
                    </Route>
                    <Route component={IsUserHasAccessToDataRoom}>
                        <Route key='IsInvestor' path='documents' component={Dataroom} />
                    </Route>

                </Route>

                <Route component={IsNotAuthorized}>
                    <Route key='loginPage' path="login" component={LoginContainer} />
                </Route>

                <Route path='*' component={GenericNotFound} />
            </Route>
        </Router>
    </Provider>
    , document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
