import "firebase/auth";
import { getApp, getApps, initializeApp } from "firebase/app";
import {
    CompleteFn,
    ErrorFn,
    getAuth,
    NextOrObserver,
    onAuthStateChanged,
    SAMLAuthProvider,
    sendPasswordResetEmail,
    signInWithCustomToken,
    signInWithEmailAndPassword,
    signInWithPopup,
    User,
    UserCredential,
} from "firebase/auth";
import firebase from 'firebase/compat/app';
import "firebase/compat/firestore"
import { firebaseClientConfig } from "./firebaseClientConfig";

/* Wrapper used to ensure firebase is imported properly */
class FirebaseService {

    idTokenPromise?: Promise<string> = undefined;
    syncToken: string | null = null;

    constructor(){
        if(!this.isInitialized()){
            initializeApp(firebaseClientConfig());
        }
    }

    isInitialized() {
        return getApps().length > 0;
    }

    initialize() {
        return initializeApp(firebaseClientConfig());
    }

    getUser() {
        return this.auth().currentUser;
    }

    async getAuthToken() {
        if (this.idTokenPromise) {
            return this.idTokenPromise;
        }
        this.idTokenPromise = this.auth().currentUser?.getIdToken(false);
        if (this.idTokenPromise) {

            this.idTokenPromise.then((token) => {
                this.syncToken = token;
            });

            try {
                return await this.idTokenPromise;
            } finally {
                this.idTokenPromise = undefined;
            }
        }
    }

    async loginUser(username: string, password: string) {
        const auth = await signInWithEmailAndPassword(this.auth(), username, password);

        auth.user.getIdToken().then(token => {
            this.syncToken = token;
        });

        return auth;
    }

    async logoutUser() {
        this.syncToken = null;
        await this.auth().signOut();
    }

    async samlSignOn(id: string) {
        const provider = new SAMLAuthProvider(id);

        return await signInWithPopup(this.auth(), provider);
    }

    onIdTokenChanged(nextOrObserver: NextOrObserver<User>, error?: ErrorFn, completed?: CompleteFn) {
        return onAuthStateChanged(this.auth(), nextOrObserver, error, completed);
    }

    async refreshToken(){
        const user = this.auth().currentUser;
        if(user) {
            return await user.getIdToken(true); // Force token refresh
        }
    }

    auth() {
        return getAuth(getApp());
    }

    async resetPassword(email: string){
        await sendPasswordResetEmail(this.auth(), email)
    }

    signInWithCustomToken(token: string): Promise<UserCredential> {
        return signInWithCustomToken(this.auth(), token);
    }

    firestore() {
        firebase.firestore();
    }

    async reloadUser() {
        await this.auth().currentUser?.reload();
        return this.getUser();
    }

}

const firebaseService = new FirebaseService();

export { firebaseService }
