import React, { useState } from 'react'
import { english } from '../Strings';
import {FormGroup, Col, Button } from 'react-bootstrap'
import { connect } from 'react-redux'

import './tc.css'
import { acceptTandC } from "../../api/dataroom/dataroom.api";
import { getUserRoles } from "../../api/userRole/userRole.api";
import { useCurrentUser } from "../../api/user/user.api";
import { userRolesReceived } from "../../redux/actions/user";
import Spinner from "../common/spinnerSmall";

type Props = {dispatch: any};

const TermsAndConditions: React.FC<Props> = (props) => {

    const [loading, setLoading] = useState(false);

    const {dispatch} = props;
    const {user} = useCurrentUser()

    const accept = async () => {
        setLoading(true);
        try {
            await acceptTandC();
            if (!!user) {
                const roles = await getUserRoles(user.id);
                if (!!roles) {
                    dispatch(userRolesReceived(roles.userRoles));
                }
            }
        } finally {
            setLoading(false);
        }
    }

    return <div id="content">
        <form>
            <h2>{english.termsAndCond_heading}</h2>
            <p>   {english.termsAndCond_p1}      </p>
            <p>   {english.termsAndCond_p2}      </p>
            <p>   {english.termsAndCond_p3}      </p>
            <p>   {english.termsAndCond_p4}      </p>
            <p>   {english.termsAndCond_p5}      </p>
            <p>   {english.termsAndCond_p6}      </p>
            <p>   {english.termsAndCond_p7}      </p>
            <FormGroup>
                <Col smOffset={2} sm={10}>
                    {loading ? <Spinner on={true} /> :
                    <Button type="button" onClick={accept}>{english.accept_tc}</Button>}
                </Col>
            </FormGroup>
        </form>

    </div>

}

export default connect(() => ({}))(TermsAndConditions)
