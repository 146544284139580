import { requestPending, responseReceived, errorReceived } from './rest';
import { getUserRoles } from "../../api/userRole/userRole.api";
import { UserRoleDTO } from "../../api/userRole/dto/UserRole.dto";
import { firebaseService } from "../../util/firebase.service";
import { getCurrentUser } from "../../api/user/user.api";
export const USER_LOGGED_OUT = 'USER_LOGGED_OUT';
export const USER_LOGIN_FAILED = 'USER_LOGIN_FAILED';
export const USER_REQUEST_PENDING = 'USER_REQUEST_PENDING';
export const USER_REQUEST_TOKEN_LOGIN_PENDING = 'TOKEN_LOGIN_PENDING';
export const USER_RESPONSE_RECEIVED = 'USER_RESPONSE_RECEIVED';
export const USER_ROLES_RECEIVED = 'USER_ROLES_RECEIVED';
export const USER_AUTH_ERROR = 'USER_AUTH_ERROR';
export const USER_RECEIVED = 'USER_RECEIVED';

const API_TOKEN = 'API_TOKEN';

export type User = {
    id: number
}

/**
 * still putting auth data into redux, as it is used by react-router
 */
export function login(username?: string, password?: string) {
    return (dispatch: any) => {

        if (!username && !password) {
            return;
        }

        // authenticate for credentials

        (async () => {


            dispatch(requestPending());

            try {
                await firebaseService.loginUser(username!!, password!!)

                const {user} = await getCurrentUser();

                dispatch(userReceived(user))

                const userRolesResp = await getUserRoles(user.id);
                if (!!userRolesResp) {
                    dispatch(userRolesReceived(userRolesResp.userRoles));
                }

                dispatch(responseReceived());

            } catch (error: any) {
                if (error?.status !== 200){
                    dispatch(loginFailed(error.status))
                }
                console.log('log in with token error:', error);
                //stop spinner
                dispatch(errorReceived(error));
            }

        })();
    }
}

export const logout = () => {
    localStorage.removeItem(API_TOKEN);
    void firebaseService.logoutUser()
    return { type: USER_LOGGED_OUT }
}

export function loginFailed(status: any) {
    localStorage.removeItem(API_TOKEN);
    return {
        type: USER_LOGIN_FAILED ,
        ...status
    }
}

export function userRequestTokenLoginPending() {
    return {
        type: USER_REQUEST_TOKEN_LOGIN_PENDING
    }
}

export const userReceived = (user: User) => ({
    type: USER_RECEIVED,
    user
})

export const userRolesReceived = (userRoles: UserRoleDTO[]) => {
    return {
        type: USER_ROLES_RECEIVED,
        userRoles
    }
}

export function userAuthError(error: any) {
    return {
        type: USER_AUTH_ERROR,
        ...error
    }
}
