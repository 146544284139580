export default [
    {major: "1", title: "Corporate Documents", minors: [
        {minor: "1", title: "Corporate Documents", subVersions: [
            {subVersion: "1", title: "Parkable & Parkable Holdings New Structure Documentation"},
            {subVersion: "2", title: "Campable New Structure Documentation"},
            {subVersion: "3", title: "Parkable Australia New Structure Documentation"}
        ]},
        {minor: "2", title: "Board Materials"},
        {minor: "3", title: "Charges & Interests"},
        {minor: "4", title: "Officers & Directors"},
        {minor: "5", title: "Corporate Structure"},
        {minor: "6", title: "Shareholders"},
        {minor: "7", title: "Previous Capital Raises"}
    ]},
    {major: "2", title: "Business Plan", minors: [
        {minor: "1", title: "Business Model"},
        {minor: "2", title: "Forecast model"}
    ]},
    {major: "3", title: "Financial Information", minors: [
        {minor: "1", title: "Accountant Details"},
        {minor: "2", title: "Financial Statements"},
        {minor: "3", title: "Financial Detail"},
        {minor: "4", title: "Assets & Liabilities"},
        {minor: "5", title: "Financial Policies & Systems"},
        {minor: "6", title: "Tax"}
    ]},
    {major: "4", title: "Property", minors: [
        {minor: "1", title: "Leases"}
    ]},
    {major: "5", title: "Insurance", minors: [
        {minor: "1", title: "Insurance Policies"},
        {minor: "2", title: "Claim History"}
    ]},
    {major: "6", title: "Litigation", minors: [
        {minor: "1", title: "Lawyer Details"},
        {minor: "2", title: "Litigation"}
    ]},
    {major: "7", title: "Government Regulation", minors: [
        {minor: "1", title: "Material licenses & authorisations"},
        {minor: "2", title: "Correspondence"}
    ]},
    {major: "8", title: "Material Contracts", minors: [
        {minor: "1", title: "Sales & Marketing"},
        {minor: "2", title: "License Agreements"},
        {minor: "3", title: "M&A Agreements"},
        {minor: "4", title: "Contracts with Change of Control"},
        {minor: "5", title: "Insiders"},
        {minor: "6", title: "Other"}
    ]},
    {major: "9", title: "Management / Employees", minors: [
        {minor: "1", title: "Organisational Chart"},
        {minor: "2", title: "Key Staff"},
        {minor: "3", title: "Employees"},
        {minor: "4", title: "Benefits"},
        {minor: "5", title: "Agreements"},
        {minor: "6", title: "ESOP"},
        {minor: "7", title: "Sales Commissions"},
        {minor: "8", title: "Policies"},
        {minor: "9", title: "Systems"}
    ]},
    {major: "10", title: "Customers, Suppliers, Marketing & Competition", minors: [
        {minor: "1", title: "Customers"},
        {minor: "2", title: "Suppliers"},
        {minor: "3", title: "Market Research"},
        {minor: "4", title: "Competitors"},
        {minor: "5", title: "Marketing Plan"},
        {minor: "6", title: "Press Releases"}
    ]},
    {major: "11", title: "Intellectual Property", minors: [
        {minor: "1", title: "Patent Attorney"},
        {minor: "2", title: "Patents & Trademarks"},
        {minor: "3", title: "Licenses"}
    ]},
    {major: "12", title: "Research & Development", minors: [
        {minor: "1", title: "R&D projects"},
        {minor: "2", title: "Government grants"}
    ]},
    {major: "13", title: "Manufacturing, Technology & Production", minors: [
        {minor: "1", title: "Major Suppliers"},
        {minor: "2", title: "Technology / software development methodology"},
        {minor: "3", title: "Product Development Planning"},
        {minor: "4", title: "Disaster Recovery"},
        {minor: "5", title: "Code Management"}
    ]},
    {major: "14", title: "Miscellaneous", minors: [
        {minor: "1", title: "Presentations"},
        {minor: "2", title: "Other"}
    ]}
]