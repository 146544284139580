import './genericnotfound.css'
import React, {Component} from 'react'
import {Grid, Row, Col, Image} from 'react-bootstrap'
import logo from '../../resources/images/parkable_logo.png'
import notFoundImage from '../../resources/images/404.png'

export default class GenericNotFoundComponent extends Component {

    onClick = () => {
        //todo
    }

    render(){

        return(
            <div className='app'>
                <Grid fluid>
                    <Row>
                        <Image responsive src={logo}/>
                    </Row>
                    <Row >
                        <Col >
                            <div >

                                <Grid fluid>
                                <Row>
                                <div className='oh-no'>Oh no!</div>
                                </Row>
                                <Row>
                                <div className='not-found'>We can't seem to find the<br/> page you're looking for.</div>
                                </Row>
                                <Row>
                                <div className='error-code'>Error code: 404</div>
                                </Row>
                                <Row>
                                <button className='start-again' onClick={this.onClick}>Click here to start again</button>
                                </Row>
                                </Grid>
                                <Image responsive className='sm-max-width'  src={notFoundImage}/>
                            </div>
                        </Col>
                    </Row>
                </Grid>
            </div>)
    }

}
