//import _ from 'lodash'

export const template = (str: string, ...args: any) => {
    let result = ''
    str.split('{}').forEach((s: string, i: number) => {
        result += s
        if (i < args.length) {
            result += args[i]
        }
    })
    return result
}

export const english = {
    _arrived: `arrived`,
    _baysAvailable: `bays available`,
    _group: `group`,
    _groups: `groups`,
    _never: `never`,
    _to: `to`,
    about: `About`,
    aboutBay: `About Bay`,
    aboutGroup: `About Group`,
    active: `Active`,
    activeSession: `Active Session`,
    accepted: `Accepted`,
    account: `Account`,
    accountDetails: `Account Details`,
    add: `Add`,
    add_users_to_group: `Add users to Group`,
    addAnotherBay: `Add another Bay`,
    addAnotherGroup: `Add another Group`,
    addBays: `Add Bays`,
    addGroup: `Add Group`,
    addMembers: `Add Members`,
    addPark: `Add park`,
    addRange: `Add Range`,
    addRangeDescription: `Add a range (e.g. A1 to A5 will create the bays A1, A2, A3, A4, A5)`,
    address: `Address`,
    addressGroup: `Address/Group`,
    adminFee: `Admin Fee`,
    all: `All`,
    applySessionToInfringement: `Apply Session to Infringement`,
    sessionApplied: `Session applied`,
    arrived: `Arrived`,
    assign: `Assign`,
    assigned_to_user: `Assigned to User`,
    assignBay: `Assign Bay`,
    assignBayToUser: `Assign Bay to User`,
    assignedTo: `Assigned to`,
    assignUserToBay: `Assign user to bay`,
    availability_event_scheduled: `Availability event scheduled`,
    available: `Available`,
    availableBetween: `Available between`,
    availableTo: `Available to`,
    bankFee: `Bank Fee`,
    bay: `Bay`,
    bay_added_back_to_parking_pool: `Bay added back to parking pool`,
    bay_assigned: `Bay assigned`,
    bay_invitation_pending: `Bay invitation pending`,
    bay_deleted: `Bay Deleted`,
    bay_is_disabled: `This bay has been suspended. You must re-enable it manually via Bay Settings`,
    bay_not_deleted: `Unable to delete bay`,
    bay_not_subscribed: `Bay not subscribed`,
    bay_optional: `Assign bay now (optional)`,
    bay_out_of_order_details: `This bay has been marked as suspended due to a complaint by a user. It will automatically be made available again when the park closes for the day. Alternatively you may manually re-enable it via Bay Settings`,
    bay_status_change_description: `Suspend bay to temporarily remove it from the system. Suspending a bay will not affect any existing session history.`,
    bay_status_set: `Bay status set`,
    bay_updated: `Bay Update`,
    bayAvailable: `Bay available`,
    bayCannotBeDeletedWhenItHasAnActiveSessionOrSubscription: `Bay cannot be deleted while it has an active session or subscription`,
    bayDescription: `We call a space where you can park a vehicle a parking bay, just so you know what we mean when we talk about parking bays or bays`,
    bayDetails: `Bay Details`,
    bayGroup: `Bay Group`,
    bayNo: `Bay`,
    bayNotFound: `Bay not found`,
    bayNumber: `Bay Number`,
    bayNumbersMustBeUnique: `Bay numbers must be unique`,
    bayOverview: `Bay Overview`,
    bays: `Bays`,
    baysAndGroups: `Bays & Groups`,
    baysInGroup: `Bays in Group`,
    baysNotInGroups: `Bays not in groups`,
    bayType: `Bay type`,
    campaigns: `Campaigns`,
    cancel: `Cancel`,
    start: `Start`,
    cancelInvite: `Cancel invite`,
    cancelled: `Cancelled`,
    ending: `Ending`,
    ended: `Ended`,
    cannotCreateParkWithoutOrganisationAdmins: `Cannot create park without organisation admins`,
    carparkNickname: `Do you have a nickname for this carpark? You can change it here.`,
    casual: `Casual`,
    casualBay: `Casual Bay`,
    visitor_bay: `Visitor Bay`,
    casualBaysAndReservedBaysThatHaveBeenPutBackOntoTheSystemOnly: `Casual Bays and Reserved Bays that have been put back onto the system only.`,
    casualBaysAreOnlyAvailableToMembersInvitedToThisGroup: `Casual Bays are only available to Members invited to this Group`,
    casualBaysAvailable: `Casual bays available`,
    casualBaysFull: `Casual bays full`,
    casualParker: `Casual Parker`,
    casualPrices: `Casual Prices`,
    change_bay_status: `Set the bay status`,
    change_bay_subscription: `Change subscription bay`,
    unable_to_update_subscription_bay: `Unable to update the bay of this subscription`,
    change_session_bay: `Change session bay`,
    chooseDateRangeAndGroupsToMakeBayAvailable: `Choose date range and groups to make bay available`,
    classic: `Classic`,
    closingTime: `Closing time`,
    close: `Close`,
    create: `Create`,
    create_park: `Create new Park`,
    createASession: `Create a session for this user`,
    createASessionForUser: `Start casual session in this bay`,
    createVisitorSession: "Create session on behalf of a visitor",
    createBays: `Create Bays`,
    createCarPark: `Create Car Park`,
    createNewBays: `Create New Bays`,
    createNewBaysForThisGroup: `Create new Bays for this Group`,
    createNewGroup: `Create New Group`,
    createNewInfringement: `Create New Infringement`,
    createOrganisation: `Create Organisation`,
    createPark: `Create Park`,
    create_more_groups: `Create more Groups`,
    create_infringement_question: `Create infringement?`,
    creating_employee_subscription: `Creating employee subscription`,
    creating_organisation: `Creating organisation`,
    creating_infringement: `Creating infringement`,
    creating_park: `Creating park`,
    creating_park_session: `Creating park session`,
    creating_park_session_question: `Create park session?`,
    creating_visitor_session: `Creating visitor session`,
    updating_visitor_session: `Updating visitor session`,
    creating_user_group: `Creating user group`,
    currentActivity: `Current Activity`,
    currentCharge: `Current charge`,
    customGroups: `Custom Groups`,
    customGroupsDescription: `You can move bays into a Custom Group. These bays are only available to Members explicitly invited to the Group`,
    dailyRate: `Daily Rate`,
    dashboard: `Dashboard`,
    date: `Date`,
    dateAvailable: `Date available`,
    defaultBayLongtermPriceHelp: `The default price for Assigned Bays. This overrides the price set on the park.`,
    defaultBayPriceDayHelp: `The default price per day. This overrides the price set on the park.`,
    defaultBayPriceDayHelpBonusBays: `The default price per day. This overrides the price set on the park for sessions on Bonus Bays.`,
    defaultBayPriceHourHelpBonusBays: `The default price per hour. This overrides the price set on the park for sessions on Bonus Bays.`,
    defaultBayPriceHourHelp: `The default price per hour. This overrides the price set on the park.`,
    defaultBaysDescription: `Default Bays are for everyone! Well, everyone in your company that is. When a member of your company hits Start Parking they might be given one of these bays.`,
    defaultGroup: `Default Group`,
    defaultLongtermPriceHelp: `The default price for Assigned Bays. Price can be overidden at Group and Bay level.`,
    defaultPriceDayHelp: `The default price per day. You can override this price at Group level.`,
    defaultPriceHourHelp: `The default price per hour. You can override this price at Group level.`,
    delete: `Delete`,
    deleted: `Deleted`,
    deleteBay: `Delete Bay`,
    deleteBayQ: `Delete Bay?`,
    deleteBays: `Delete Bays`,
    deleteNoteQuestion: `Delete Note?`,
    deleteInfringementQuestion: `Delete Infringement?`,
    deleteNote: `Delete Note`,
    deleting_bay: `Deleting Bay`,
    deletingABayIsIrreversible: `Deleting a bay is irreversible and all data will be lost`,
    disabled: `Disabled`,
    discard_changes: `Discard Change`,
    displayName: `Display name`,
    done: `Done`,
    duplicateSignage: `Duplicate signage`,
    editCarParkSettings: `Edit car park settings`,
    editGroupSettings: `Edit group setting`,
    egMarketing: `e.g. Marketing`,
    email: `Email`,
    employeeSubscription: `Employee Subscription`,
    employee_subscription_created: `Employee subscription created`,
    employee_subscription_updated: `Employee subscription updated`,
    enabled: `Enabled`,
    eg_30: `eg. 30`,
    ended_at: `Ended at`,
    end_session_q: `End session?`,
    end_this_parking_session: `End this parking session`,
    add_registration_visitor_session: `Add/update registration`,
    end_visitor_session: `End visitor session`,
    endSession: `End session`,
    enterBayNumber: `Enter bay number e.g. A1`,
    enterRegistration: `Enter registration`,
    enterBayRange: `Enter Bay Range`,
    enterSignage: `Enter signage`,
    enterTheirEmailAddress: `Enter their email address`,
    error: `Error`,
    field: `Field`,
    fillOutAllTheFields: `Fill out all the fields`,
    firstName: `First name`,
    chargeDailyRate: `Charge Daily Rate`,
    willBeChargeOneDailyRate: `Will be charge one daily rate`,
    willBeChargeTwoDailyRate: `Will be charge two daily rate`,
    plusAdminFee: ` + 25.00$ Admin Fee`,
    for_business: `For Business`,
    from_date: `From date`,
    full: `Full`,
    timeExceeded: `Time Exceeded`,
    generalSettings: `General Settings`,
    get_next_page: `Get next page`,
    group: `Group`,
    group_created: `Group created`,
    grouped_bays: `Grouped Bays`,
    groupAdmin: `Admin`,
    grouped_bays_are_like_normal_bays_but_with_: `Grouped Bays are like normal Bays but with `,
    grouped_bays_are_hidden_from_general_staff: `Grouped Bays are hidden from general staff then made available to selected members only.`,
    grouped_bays_are_reserved_for_members: `Grouped Bays are reserved for Members who have been invited to the Group.`,
    super_powers: `Super Powers.`,
    groupDescription: `Parking bays can be arranged into groups. Bays can belong to one group only, but staff users can belong to multiple groups.`,
    groupEarnings: `Group Earnings`,
    groupNamesMustBeUnique: `Group names must be unique`,
    groupOverview: `Group Overview`,
    groups: `Groups`,
    groups_available_to: `These Groups can park here`,
    groupsAvailableTo: `Groups available to`,
    gross: `Gross`,
    hostedParks: `Hosted Parks`,
    ifAReservedBayIsNotBeingUsed: `If a Reserved Bay is not being used, the Bay can be made available to invited team members. Their sessions will automatically end at midnight.`,
    images: `Images`,
    in: `In`,
    infringements: `Infringements`,
    access_control: `Access Control`,
    infringementRevenue: `Infringement Revenue`,
    infringementDeleted: `Infringement Deleted`,
    infringementSaved: `Infringement Saved`,
    invalidEmail: `Invalid email`,
    inviteAnotherTeamMember: `Invite another team member`,
    invited: `Invited`,
    inviteEmailsToGroup: `Invite emails to group`,
    inviteMembers: `Invite Members`,
    add_team_leads: `Add Team Leads`,
    inviteMembersOrg: `Invite members to your organisation.`,
    invites: `Invites`,
    invites_sent: `Invites sent`,
    inviteTeamMembers: `Invite Team Members`,
    isNotUsingTheirBayToday: `is not using their bay today.`,
    isParkingHere: `is parking here.`,
    lastName: `Last name`,
    leases: `Leases`,
    lets_do_this: `Let's do this!`,
    loading: `Loading...`,
    loadMore: `Load more...`,
    locations: `Car Parks`,
    log_out: `Log out`,
    longTermPriceDefault: `Subscription Parking Default Price`,
    longTermParking: `Long Term Parking`,
    made_user_admin: `Made user admin`,
    make_user_team_leader: `Promote to Team Lead`,
    makeAvailable: `Make available`,
    makeBayAvailable: `Make Bay Available`,
    makeOrganisationAdmin: `Promote to Organisation Administrator`,
    makeTeamLeader: `Make team leader`,
    making_user_admin: `Making user admin`,
    member: `Member`,
    memberOverview: `Member Overview`,
    members: `Members`,
    membersInGroup: `Members in Group`,
    membersInvitedToThisGroupCanParkHere: `Members invited to this Group can park here`,
    minimumChargeIsDailyRate: `Minimum Charge Is Daily Rate`,
    month: `Month`,
    move_multiple_bays: `Move multiple bays`,
    moveBaysIntoThisGroup: `Move Bays into this Group`,
    moveSelectedBaysToThisGroup: `Move selected bays to this group`,
    name: `Name`,
    open: `Open`,
    nameNotFound: `Name not found`,
    nameOfGroup: `Name of Group`,
    net: `Net`,
    noActiveSessionFound: `No active session found`,
    noBays: `No Bays`,
    noAvailableBays: `No Available Bays`,
    noAvailableParks: `No Available Parks `,
    noEmails: `No emails`,
    noGroups: `No Groups`,
    noPreviousInfringements: `No previous infringements`,
    noneScheduled: `None scheduled`,
    notUsingToday: `Not using today`,
    note: `Note`,
    notes: `Notes`,
    occupied: `Occupied`,
    openingTime: `Opening time`,
    operatingHours: `Operating Hours`,
    options: `Options`,
    organisation_created: `Organisation created`,
    organisation_updated: `Organisation updated`,
    organisationAdmin: `Organisation Admin`,
    other_vehicle: `Other Vehicle`,
    other_vehicles: `Other Vehicles`,
    out: `Out`,
    out_of_order: `Out of order`,
    overview: `Overview`,
    park: `Park`,
    park_created: `Park Created`,
    parkDetails: `Park Details`,
    park_session_created: `Park session created`,
    visitor_session_created: `Visitor session created`,
    visitor_session_updated: `Visitor session updated`,
    park_updated: `Park updated`,
    park_updating: `Park updating`,
    parkingActivity: `Parking Activity`,
    parkingNow: `Parking now`,
    parks_slash_sessions: `Parks/Sessions`,
    parksSessions: `Park Sessions`,
    parkTomorrow: `Park Tomorrow`,
    paymentInfo: `Payment Info`,
    pending: `Pending`,
    plateNo: `Plate No.`,
    plateNumber: `Plate number`,
    plates: `Plates`,
    phone: `Phone`,
    price: `Price`,
    pricePerDay: `Price per day`,
    pricePerHour: `Price per hour`,
    raffleParks: `Raffle Parks`,
    re_enable: `Re-enable`,
    refunds: `Refunds`,
    remittanceAdvice: `Remittance Advice`,
    remove: `Remove`,
    remove_admin_status: `Demote from Organisation Administrator`,
    remove_from_group: `Remove Member from Group`,
    remove_team_leader_status: `Demote from Team Lead`,
    removed_admin_status: `Removed admin status`,
    removeMember: `Remove Member`,
    removing_admin_status: `Removing admin status`,
    removing_user: `Removing user`,
    removing_user_suspension: `Removing user suspension`,
    removingAMemberRemovesThem: `Removing a member removes them from this organisation. This will also end any active sessions or subscriptions for this member`,
    required: `Required`,
    resendInvite: `Resend Invite`,
    isReservation: `Is Reservation`,
    reserved: `Reserved`,
    reservedBay: `Reserved Bay`,
    reservedBaysAvailable: `Reserved bays available`,
    reservedBaysEmpty: `Reserved bays empty`,
    reservedFor: `Reserved For`,
    reservedForInvite: `Awaiting response...`,
    reservedParker: `Reserved Parker`,
    role: `Role`,
    save: `Save`,
    save_changes: `Save Changes`,
    scheduledAvailability: `Scheduled Availability`,
    scheduledBayAvailability: `Scheduled Bay Availability`,
    scheduleNewAvailabilityEvent: `Schedule new availability event`,
    scheduling_availability_event: `Scheduling availability event`,
    searchBays: `Search Bays`,
    searchEmails: `Search emails`,
    searchMembers: `Search Members`,
    searchNotes: `Search Notes`,
    searchInfringements: `Search Infringements`,
    searchSessions: `Search Sessions`,
    searchSubscriptions: `Search Subscriptions`,
    searchVehicles: `Search Vehicles`,
    searchUsers: `Search Users`,
    secondDailyRate: `2nd Daily Rate`,
    select: `Select`,
    select_all: `Select all`,
    select_bay: `Select bay`,
    select_none: `Select none`,
    selectAPark: `Select a park`,
    sending_invites: `Sending invites`,
    sendInvites: `Send invites`,
    session_updated: `Session updated`,
    sessionCompleted: `Session completed`,
    sessionDetails: `Session details`,
    sessions: `Sessions`,
    active_sessions: `Active sessions`,
    setting_bay_status: `Setting bay status`,
    settings: `Settings`,
    some_existing_users: `Some emails matched existing users, these users have been made members`,
    sortBy: `Sort by`,
    staffBays: `Staff Bays`,
    staffBaysDescription: `Staff bays are for everyone! Well, everyone in your company, that is. When a member of your company hits Start Parking they might be given one of these bays`,
    startHere: `Start here`,
    status: `Status`,
    operate: `Operate`,
    submit: `Submit`,
    success: `Success`,
    subscribedTo: `Subscribed to`,
    subscription: `Subscription`,
    suspend_on_off: `Suspend (On/Off)`,
    suspendAccount: `Suspend Account`,
    suspended: `Suspended`,
    suspended_bays: `Suspended Bays`,
    suspending_user: `Suspending user`,
    suspendOffOn: `This bay is`,
    started: `Started`,
    team_leader: `Team leader`,
    termStarted: `Term started`,
    termEnd: `Term end`,
    theLastTimeTheyMadeTheirBayAvailableWas: `The last time they made their bay available was`,
    thereIsAnExistingBayWithThisNumber: `There is an existing bay with this number`,
    thereIsAnExistingGroupWithThisName: `There is an existing group with this name`,
    this_bay_is_reserved_for_an_invite: `This bay is locked until the invitation has been accepted.`,
    thisBayIsReservedFor: `This bay is reserved for`,
    time: `Time`,
    timeInBay: `Time in bay`,
    timeOfRaffle: `Time of raffle`,
    to: `To`,
    to_date: `To date`,
    total: `Total`,
    totalEarnedBackToday: `Total earned back today`,
    turnBayOff: `Suspend Bay`,
    unable_to_create_employee_subscription: `Unable to create employee subscription`,
    unable_to_create_group: `Unable to create group`,
    unable_to_create_organisation: `Unable to create organisation`,
    unable_to_create_park: `Unable to create park`,
    unable_to_create_park_session: `Unable to create park session`,
    unable_to_retrieve_access_gates: 'Unable to get Access Gates',
    unable_to_open_access_gate: 'Unable to open Gate',
    gate_opened: 'Gate Opened',
    unable_to_create_visitor_session: `Unable to create visitor session`,
    unable_to_update_visitor_session: `Unable to update visitor session`,
    unable_to_make_user_admin: `Unable to make user admin`,
    unable_to_remove_admin_status: `Unable to remove admin status`,
    unable_to_remove_user: `Unable to remove user`,
    unable_to_remove_user_suspension: `Unable to remove user suspension`,
    unable_to_schedule_availability_event: `Unable to schedule availability event`,
    unable_to_send_invites: `Unable to send invites`,
    unable_to_set_bay_status: `Unable to set bay status`,
    unable_to_suspend_user: `Unable to suspend user`,
    unable_to_update_employee_subscription: `Unable to update employee subscription`,
    unable_to_update_organisation: `Unable to update organisation`,
    unable_to_update_park: `Unable to update park`,
    unable_to_update_session: `Unable to update session`,
    unable_to_charge_the_infringer_user_does_not_have_a_registered_card: `Unable to charge the infringer, user doesn't have a registered card.`,
    unavailable_for_rest_of_day: `Suspended until tomorrow (will automatically re-enable)`,
    visitor_session_id: "Visitor Session ID",
    add_rego_to_visitor_session: "You may optionally enter a vehicle registration for this visitor session.",
    confirm_end_visitor_session: "Please confirm you would like to end this visitor session.",
    update_rego_in_visitor_session: "Enter new vehicle registration.",
    updating_bay: `Updating Bay`,
    updating_bays: `Updating Bays`,
    updating_employee_subscription: `Updating employee subscription`,
    updating_organisation: `Updating organisation`,
    updating_park: `Updating park`,
    updating_session: `Updating session`,
    uploadingImages: `Uploading Images`,
    user_removed: `User removed`,
    user_suspended: `User suspended`,
    user_suspension_removed: `User suspension removed`,
    useRaffleSystem: `Use a raffle system to allocate next days parking`,
    useRaffleSystemHelp: `People wishing to park the following day will be notified if they won the raffle at the time you have selected below.`,
    userGroups: `User Groups`,
    username: `Username`,
    userDetails: `User Details`,
    userNotFound: `User not found`,
    users: `Users`,
    userType: `User type`,
    usingBay: `Using Bay`,
    reports: `Reports`,
    value: `Value`,
    vehicle: `Vehicle`,
    vehicles: `Vehicles`,
    viewGroup: `View Group`,
    vouchers: `Vouchers`,
    we_are_excited: `We are excited to have you on board, are you ready to rock this world?!`,
    whatIsABay: `What is a bay?`,
    whatIsAGroup: `What is a group?`,
    woohoo_welcome: `Woohoo! Welcome to Parkable for Business`,
    youCanTemporarilySuspendThisMember: `You can temporarily suspend this member so they cannot park in any of the organisation's Bays. Suspending will also end any active sessions or subscriptions for this member`,
    youMustSelectACar: `You must select a car`,

    group_updated: "Group Updated",
    updating_group: "Updating Group",
    error_removing_user: "Error removing user",
    member_invited: `Member invited`,
    end_subscription: "End Subscription",
    end_subscription_q: `End User Subscription?`,
    changing_bay: "Changing bay",
    bay_changed: "Bay changed",
    unable_to_change_bay: "Unable to change bay",
    search_bay_signage: "Search bay signage",
    change_bay_modal_title: "Select a new bay to assign to the session",
    making_user_team_leader: `Making user team leader`,
    made_user_team_leader: `Made user team leader`,
    unable_to_make_user_team_leader: `Unable to make user team leader`,
    removing_team_leader_status: `Removing team leader status`,
    removed_team_leader_status: `Removed team leader status`,
    unable_to_remove_team_leader_status: `Unable to remove team leader status`,
    adding_users_to_group: `Adding users to group`,
    added_users_to_group: `Added users to group`,
    unable_to_add_users_to_group: `Unable to add users to group`,
    unable_to_add_user_note: `Unable to add note to user`,
    unable_to_delete_user_note: `Unable to delete note from user`,
    unable_to_delete_infringement: `Unable to delete infringement`,
    remove_member_from_organisation_q: `Remove member from organisation?`,
    default_groups: `Default groups`,
    creating_voucher: `Creating voucher`,
    voucher_created: `Voucher created`,
    unable_to_create_voucher: `Unable to create voucher`,
    create_voucher: `Create voucher`,
    unable_to_parse_percent_discount: `Unable to parse percent discount`,
    percent_discount: `Percent discount`,
    give_user_discount_voucher: `Give user discount voucher`,
    choose_location: `Choose location`,
    discount: `Discount`,
    no_display_name_set: `No display name set`,
    optional: `Optional`,
    you_can_give_more_detailed_instructions_here: `You can give more detailed instructions relating to this carpark here`,
    description: `Description`,
    view_carpark: `View Carpark`,
    car_park: `Car Park`,
    assigning_bays_to_group: `Assigning Bays to Group`,
    bays_assigned_to_group: `Bays assigned to Group`,
    unable_to_assign_bays_to_group: `Unable to assign Bays to Group`,
    visitor_session_notifications: `Visitor Session Notifications`,
    configure_notification_emails_visitor_sessions: `You can configure notification emails to be sent to your team leads when vehicles have been parked in your visitor bays for longer than you expect.`,
    allocating_bonus_bays: `Allocating Bonus Bays`,
    not_set: `Not set`,
    price_per_week: `Price per Week`,
    price_per_month: `Price per Month`,
    use_all_groups_in_park: `Use all groups in park`,
    use_specified_groups: `Use specified groups`,
    when_members_make_their_assigned_bays_available_all_groups_in_park_are_allowed: `When members make their assigned bays available, members of all groups in the park can park here`,
    the_specified_groups_will_be_used_admin_can_still_select: `The specified groups will be used. Admin can still select groups when making a bay available`,
    anyone: `Anyone`,
    specific_groups: `Specific groups`,
    bonus_bays_will_be_available_for_anyone_who_belongs: `Bonus Bays will be available for anyone who belongs to a group in this park`,
    select_which_groups_can_use_bonus_bays: `Select which groups can use Bonus Bays:`,
    add_to_default_make_available_groups: `Add this group to the list for Bonus Bays`,
    subscriptions_end_at_term_end: `Subscriptions end at term end`,
    subscriptions_end_at_term_end_description: `If selected, when a member with an assigned bay ends their subscription, `
        + `the subscription will continue for one term length. `
        + `If not selected, the subscription will end immediately. `
        + `Admin users may choose to end subscriptions immediately or at the end of the term`,
    end_subscription_immediately: `End Subscription immediately`,
    end_subscription_after_one_term: `End Subscription after one term`,
    this_subscription_will_end_at_the_end_of_the_term: `This subscription will end at the end of the term`,
    when_someone_with_an_assigned_bay_cancels: `When someone with an assigned bay cancels their subscription, when will the subscription come to an end?`,
    immediately: `Immediately`,
    at_the_end_of_one_more_term: `At the end of one more term`,
    if_you_select_this_option: `If you select this option, it means that when someone cancels a subscription, they will have one full term until their assigned bay ends`,
    admin_users_have_the_option: `Note: Admin users can manually end subscriptions immediately or at the term end`,
    a_subscription_bay_which_is_made_available_to_others_is_a_bonus_bay: `* A subscription bay which is made available to others is a Bonus Bay`,
    who_can_use_bonus_bays: `Who can use Bonus Bays?`,
    remove_this_day: `Remove this day`,
    updating_availability_days: `Updating availability days`,
    availability_days_updated: `Availability days updated`,
    unable_to_update_availability_days: `Unable to update availability days`,
    duplicate_email: `Duplicate email`,
    subscription_ending: `Subscription ending`,
    unable_to_find_date: `Unable to find date`,
    payment_failed: `Payment failed`,
    date_newest_first: `Date (newest first)`,
    date_oldest_first: `Date (oldest first)`,
    time_newest_first: `Time (newest first)`,
    time_oldest_first: `Time (oldest first)`,
    bay_features: `Bay features`,
    what_type_of_bays_are_in_this_group: `What type of bays are in this group?`,
    standard_bays: `Standard bays`,
    motorbike_bays: `Motorbike bays`,
    motorbike_bay: `Motorbike bay`,
    type: `Type`,
    park_info: `Park info`,
    subscribers_and_registrations: `Subscribers and registrations`,
    show_table: `Show table`,
    hide_table: `Hide table`,
    searchOrganisations: `Search organisations`,
    amount: `Amount`,

    updating_agent: `Updating agent`,
    agent_updated: `Agent updated`,
    end: "End",
    update: "Update",
    messages: `Messages`,
    back: `Back`,
    send: `Send`,
    reply: `Reply`,
    public_reply: `Public reply`,
    internal_note: `Internal note`,
    tags: `Tags`,
    created_at: `Created at`,
    updated_at: `Updated at`,
    comments: `Comments`,
    search_messages: `Search messages`,
    updating_message: `Updating message`,
    message_updated: `Message updated`,
    unable_to_update_message: `Unable to update message`,
    updated: `Updated`,
    all_messages: `All messages`,
    open_messages: `Open messages`,
    pending_messages: `Pending messages`,
    hold_messages: `Hold messages`,
    solved_messages: `Solved messages`,
    closed_messages: `Closed messages`,
    subject: `Subject`,
    requester: `Requester`,
    unread: `Unread`,
    bay_signage: `Bay Signage`,
    ticket_number: `Ticket #`,
    submit_as: `Submit as`,
    conversations: `Conversations`,
    parkable_admin: `Parkable admin`,
    vehicle_ids: `Vehicle ids`,
    parksession_id: `ParkSession id`,
    employee_subscription_id: `EmployeeSubscription id`,
    user_id: `User id`,
    invite_id: `Invite id`,
    reload: `Reload`,
    show: `Show`,
    hide: `Hide`,
    bulk_campaigns: `Bulk campaigns`,
    selected: `Selected`,
    reload_vouchers: `Reload vouchers`,
    load_vouchers: `Load vouchers`,
    total_sessions: `Total sessions`,
    total_vouchers: `Total vouchers`,
    unique_users: `Unique users`,
    hide_detail: `Hide detail`,
    show_detail: `Show detail`,
    fully_used: `Fully used`,
    remaining_credit: `Remaining credit`,
    remaining_sessions: `Remaining sessions`,
    y: 'Y',
    n: 'N',
    after_21_03_2018: `After 21/03/2018`,
    standard_campaigns: `Standard campaigns`,
    reload_campaigns: `Reload campaigns`,
    load_campaigns: `Load campaigns`,
    code: `Code`,
    message: `Message`,
    select_date: `Select date:`,
    filter: `Filter`,
    clear: `Clear`,
    select_one: `Select one`,
    eg_my_username: 'e.g. myUsername',
    assignBayAndSetActive: `Assign Bay and activate sub (admin only)`,
    activate_subscription: `Activate subscription (admin only)`,
    activate: `Activate`,
    activate_subscription_q: `Activate subscription? A payment by the user will be triggered`,
    create_session_for_user: `Create session for user`,
    admin_only: `Admin only`,
    activating: `Activating...`,
    past_due: `Past due`,
    subscriptions: `Subscriptions`,
    retrieving_infringements: `Retrieving infringements`,
    infringements_received: `Infringements received`,

    moved_to_new_bay: `Moved to new bay`,
    session_ended: `Session ended`,
    admin_contacted: `Admin contacted`,
    bay_returned_to_group: `Bay returned to group`,
    session_created: `Session created`,
    registration: `Registration`,
    rego: `Rego`,
    infringer: `Infringer`,
    reporter: `Reporter`,
    valid_from: `Valid from`,
    valid_to: `Valid to`,
    currently_valid: `Currently valid`,
    casual_parking: `Casual parking`,
    subscription_parking: `Subscription parking`,
    valid_for_parking: `Valid for parking`,
    filter_codes: `Filter codes`,
    stripe_subscription_id: `Stripe subscription id`,
    load_events: `Load events`,
    id: `id`,
    subscription_id: `Subscription id`,
    line_items: `Line items`,
    unable_to_load_events: `Unable to load events`,
    go: `Go`,
    payment_succeeded: `Payment succeeded`,
    invoice_created: `Invoice created`,

    invitedSubscriptionDescription: `Member invited to a subscription, and can accept in invite in the app. The first payment will be processed when they accept the invite`,
    pastDueSubscriptionDescription: `One or more payments failed`,
    activeSubscriptionDescription: `Subscription is active`,
    endingSubscriptionDescription: `Subscription will end after the current term finishes`,
    endedSubscriptionDescription: `Subscription ended`,
    discount_should_be_between: `Discount should be between 1% and 100%`,
    select_a_park: `Select a park`,
    select_date_range: `Select date range`,
    date_range_is_invalid: `Date range is invalid`,
    choose_valid_parking_types: `Choose valid parking types`,
    user: `User`,
    reload_users: `Reload users`,
    load_users: `Load users`,
    this_is_a_visitor_group: `This is a visitor group`,
    baysForVisitorsDescription: `These bays are for visitor parking only.  Members of your organisation will not be able to park here`,
    bayAvailableToVisitors: "Bay is available to visitors",
    visitorIsParkingHere: `A visitor is parking here.`,
    visitorIsParkingHereExceededTime: `A visitor is parking here and has exceeded the expected parking time.`,
    startVisitorSession: 'Start visitor session',
    updateVisitorSession: 'Update visitor session',
    endVisitorSession: 'End visitor session',


    almostThere: `Almost there...`,
    inOrderToGetPaidMoreDetailsNeed: `In order to get paid, there's a few more details we need`,
    enterYourInformationOnTheFormBelow: `Enter your information on the form below`,
    ifYouAreAHostNeedDetailsFromYourEarnings: `If you’re a host, we need a few details from you so we can put your earnings in your account and pay GST if needed`,
    bankName: `Bank Name`,
    bankAccountHolder: `Bank Account Holder`,
    changeBankAccountDetails: `Change Bank Account Details`,
    fieldsMarkedWithAsteriskAreRequired: `Fields marked with * are required`,
    placeholderFirstName: `e.g. John`,
    placeholderLastName: `e.g. Smith`,
    accountHolderFirstName: `Account Holder First Name`,
    accountHolderLastName: `Account Holder Last Name`,
    dateOfBirth: `Date of Birth (dd/mm/yyyy)`,
    placeholderDateFormat: `dd/mm/yyyy`,
    bankAccountNumber: `Bank Account Number`,
    placeholderNewZealandBankAccountNumber: `e.g. 00-1234-5678912-00`,
    placeholderAustraliaBankAccountNumber: `e.g. 000-000-0000000000`,
    weAlreadyHaveYourBankAccountNumber: `We have registered your bank details. If you want to change your details, click here`,
    selectCompanyType: `Select Company Type`,
    GSTNumber: `GST Number`,
    placeholderGSTNumber: `e.g. 123123123`,
    companyName: `Company Name`,
    companyNumber: `Company Number`,
    placeholderCompanyName: `e.g. Parkable Limited`,
    placeholderCompanyNumber: `e.g. 9429045861765`,
    accountHostDetails: `Account Host Details`,
    photoIdFront: `Photo Id - Front`,
    photoIdBack: `Photo Id - Back`,
    personalId: `Personal Id (Passport / driver's license)`,
    placeholderPersonalId: `e.g. FA123456`,
    passportId: `Passport Id`,
    driverLicenseId: `Driver's license Id`,
    installations: `Installations`,
    weAlreadyHaveYourPersonalIdNumber: `We have registered your Personal Id Number. If you want to change your document details, click here`,
    failedVerificationPersonalId: `Your Identity document is not legible. Please try again`,
    yourIdentityDocumentVerificationHasBeenInitiated: `Your Identity document verification has been initiated`,
    yourIdentityDocumentIsVerified: `Your Identity document is verified`,
    dragNDropLabel: `Drop images here, or click to select images to upload`,
    dragNDropPassportPhoto: `Click here or drag your passport photo to upload`,
    dragNDropDriverLicenseFrontPhoto: `Click here or drag your driver's license front photo to upload`,
    dragNDropDriverLicenseBackPhoto: `Click here or drag your driver's license back photo to upload`,
    infoDetailStripeVerificationId: `Once your account exceeds $2,000, photo ID is needed so that banks can verify automated payments (so they know that you're you!). If you reach $2,000 without providing photo ID, payouts will be paused until the information is added`,
    payout: `Payout`,
    paymentsMayBeTemporarilyHeld: `If you have not registered ID verification documents, some payments may be temporarily held. You can register your documents on the Payment Info page`,
    casualSessionRevenue: `Casual Session Revenue`,
    subscriptionRevenue: `Subscription Revenue`,
    parkableFee: `Parkable Fee`,
    fillOutYourPaymentDetailsToCheckMonthReport: `We've gone automated! Fill out your details in the "Payment Details" tab to switch over to our new system then check back in at month end to view and download your reports.`,
    downloadCSV: `Download CSV`,
    downloadPDF: `Download PDF`,

    occupancy: `Occupancy`,
    baysMadeAvailable: `Bays Made Available`,
    bonusSession: `Bonus Session`,
    monday: `Monday`,
    tuesday: `Tuesday`,
    wednesday: `Wednesday`,
    thursday: `Thursday`,
    friday: `Friday`,
    saturday: `Saturday`,
    sunday: `Sunday`,

    delete_note: `Delete note`,
    delete_note_q: `Delete note?`,
    create_note: `Create note`,
    edit: `Edit`,
    group_notes: `Group notes`,
    park_notes: `Park notes`,
    bay_notes: `Bay notes`,
    infringement_notes: `Infringement notes`,
    no_eligible_users_in_group: `Hi Admin! There are no names in this list because everyone in the group is already sorted`,
    group_type: `Group type`,
    which_users_can_park_in_this_group: `Which users can park in this group?`,
    only_members_who_have_been_added_to_this_group: `Only members who have been added to this group`,
    open_to_the_public_for_casual_parking: `Open to the public for casual parking`,
    open_to_the_public_for_long_term_parking: `Open to the public for long-term parking`,
    long_term_prices: `Long Term prices`,
    additional_terms: `Additional terms`,
    optional_terms_in_addition_to_the_parkable_general_terms_and_conditions: `Optional terms in addition to the Parkable general terms and conditions`,
    mins: `mins`,
    setting_the_group_type_to_public_will: `Setting the group type to public will remove the price per hour and price per day for this group`,
    a_user: `A user`,
    has_reserved_this_bay: `Has reserved this bay.`,
    load_public_members: `Load public members`,
    public_members: `Public members`,
    this_park_has_public_groups: `This park has public groups`,
    this_option_does_not_apply_to_subscriptions_in_public_groups: `Note: This option does not apply to subscriptions in public groups`,

    day_by_day: `Day by Day`,
    failed_transactions: `Failed Transactions`,

    enterEmailAddress: `Enter email address`,
    businessRepresentativeDetails: `Business Representative Details`,
    bankAccountDetails: `Bank Account Details`,
    placeholderCity: `e.g. Auckland`,
    city: `City`,
    placeholderPostCode: `e.g. 1025`,
    postCode: `Post code`,
    placeholderCountry: `e.g. New Zealand`,
    country: `Country`,
    percentageBonusBaysShare: `(%) Bonus Bays Share For Subscription Holder`,
    subscriptionTermsAndConditions: `Subscription terms and conditions`,
    stripeAccount: `Stripe Account`,
    adminSettings: `Admin Settings`,
    parkableSessionMargin: `Parkable Margin on Casual Session`,
    parkableSessionMarginHelp: `(%) Parkable Fee on Casual Sessions`,
    parkableSubscriptionMargin: `Parkable Margin on Subscription Revenue`,
    parkableSubscriptionMarginHelp: `(%) Parkable Fee on Subscription Revenue`,
    refundSessionQuestion: `Refund session?`,
    refundSession: `Refund session (admin only)`,
    ok: `Ok`,
    reason: `Reason`,
    refunded: `Refunded`,
    placeholderRefundReason: `e.g. Another car is on my bay`,
    noCards: `No Cards`,
    creditCards: `Credit Cards`,
    addNewCreditCard: `Add new credit card`,
    createNewCreditCard: `Create new credit card`,
    creditCardNumber: `Credit Card Number`,
    placeholderCreditCardNumber: `0000 - 0000 - 0000 - 0000`,
    placeholderMonth: `MM`,
    placeholderYear: `YY`,
    placeholderCreditCardCVV: `CVV`,
    invalidMonth: `Invalid Month`,
    invalidYear: `Invalid Year`,
    cardExpired: `Card Expired`,
    invalidCVV: `Invalid CVV`,
    invalidCreditCardNumber: `Invalid Credit Card Number`,
    unableToAddCreditCard: `Unable to add a credit card`,
    unableToChangeDefaultCard: `Unable to change default card`,
    changeDefault: `Change Default`,
    default: `Default`,
    deleteCreditCardQuestion: `Delete this card?`,
    creditCardDeleted: `Credit Card Deleted`,
    creditCardDefaultChanged: `Credit Card Default Changed`,
    creditCardCreated: `Credit Card Created`,
    change: `Change`,
    territory: `Territory`,
    subscriptionsSettings: `Subscriptions Settings`,
    cancellingSubscriptions: `Cancelling Subscriptions`,
    bonusBaysSettings: `Bonus Bays Settings`,
    bonusBaysDescription: `If someone with a subscription is away from the office or not using their bay, they can make their parking bay available to others in the team, who can use it for casual parking. Instead of going unoccupied, this creates 'bonus bays' ensuring that all of your parking space is being used efficiently.`,
    bonusBaysShareDescription: `When someone parks casually in a 'bonus bay', they pay the standard daily or hourly rate. Admin can decide whether this payment is retained by the business, given to the subscription holder (as a discount on their next subscription payment), or shared between the business and the subscription holder.`,
    voucherDiscount: `Voucher Discount`,
    bonusBaysShare: `Bonus Bays Share`,
    publicSubscriptionsDescription: `With public subscriptions, both the host and the parker are required to give fair notice of the ending of a subscription`,
    officer: `Officer`,
    showMenu: `Show menu`,
    hideMenu: `Hide menu`,
    no_vehicles_found_with_that_registration: `No vehicles found with that registration`,
    filter_infringements: "Filter Infringements",
    state: `State`,
    placeholderState: `e.g. Queensland`,
    pleaseSelectAnAddressFromTheOptionsProvidedInTheDropdown: `Please select an address from the options provided in the dropdown`,
    parkId: "Park Id",
    sessionId: "Session Id",
    leaseId: "Lease Id",
    employeeSubscriptionId: "Employee Subscription Id",
    NavPanelItemMessages: "Messages",
    no_msgs_to_show: "No messages to show",
    user_to_user: "User to user",
    messages_admin: 'Admin',
    messages_pending: 'Pending',
    messages_utu_messages: 'User to user messages',
    messages_all_messages: 'All messages',
    messages_admin_messages: 'Admin messages',
    messages_pending_messages: 'Pending messages',
    MessagesTableView_Date: 'Date',
    MessagesTableView_Sender: 'Sender',
    Receiver:'Receiver',
    MessagesTableView_Body: 'Body',
    MessagesTableView_Reply: 'Reply',
    CommentTableViewCell_Allow: "Allow",
    CommentTableViewCell_Deny: "Deny",
    MessageTableViewCell_Send: "Send",
    MessageTableViewCell_OpenThread: (num: number) => {
        return parse(num, "Open thread ({=0 {no comments.} =1 {one comment.} other {# comments.}})");
    },
    MessageTableViewCell_CloseThread: (num: number) => {
        return parse(num, "Close thread ({=0 {no comments.} =1 {one comment.} other {# comments.}})");
    },
    placeholderABNNumber: `e.g. 123123123`,
    ABNNumber: `ABN Number`,
    BSBNumber: `BSB Number`,
    placeholderBSBNumber: `e.g. 111100`,
    placeholderBankAccountNumber: `e.g. 123456`,
    deposit: "Deposit",
    deposit_status: "Deposit Status",
    create_deposit: "Create Deposit",
    charge_user: "Charge User",
    refund_to_user: "Refund to user",
    refund_to_park_owner: "Refund to host",
    device_name: "Device Name",
    deposit_amount: "Deposit Amount",
    currency_sign: "$",
    confirm: "Confirm",
    processing: "Processing....",
    deposit_status_paid: "Paid",
    peyment_failed: "Payment Failed",
    refundToHost: "Refund To Host",
    refundToUser: "Refund To User",
    refundedToHost: "Refunded To Host",
    refundedToUser: "Refunded To User",
    refundPending: "Refund Pending",
    refund_success: "Refund Success",
    deposit_payment_failed: "Failed to process payment",
    deposit_refund_failed: "Refund Failed",
    carparkDetails:'Carpark details',
    carparkType:"Carpark Type",
    carparkTypeHead:"Public/Private",
    carparkPublic:"Public carpark",
    carparkPrivate:"Private carpark",
    carparkAlwaysOpen:"This carpark is always open",
    carparkAlwaysOpenCustom:"Custom",
    carparkAlwaysOpenFyi:"FYI: There are additional features when you carpark closes each night.",
    carparkAlwaysOpenPopOver:"There are a few advantages to having bay numbers on your bays. You can assign Parkers to specific bays, and in turn they can earn money when another Parker uses their bay.",
    day:"Day",
    subscription_bay_settings:"Subscription bay settings",
    parkHasSubscriptionBays:"This carpark has subscription bays",
    sub_cancel_head:"Subscription cancellation rule",
    sub_cancel_desc:"When a parker cancels their subscription...",
    sub_finish_end_of_month:"…they may continue using the bay until the original term is complete. The bay cannot be reassigned until their term is finished.",
    sub_finish_immediate:"…their term is terminated and they cannot use the bay anymore. The bay is immediately available and can be reassigned.",
    sub_fyi:"FYI: Admin users always have the option of ending a subscription immediately or at the end of the current term.",
    bay_settings:"Bay settings",
    bay_settings_no_numbers:"Carpark has no bay numbers (pick your spot)",
    bay_settings_has_numbers:"Carpark has bay numbers (allocated)",
    bay_settings_fyi:"FYI: There are additional features when you have bay numbers",
    bay_settings_fyi_pop:"There are a few advantages to having bay numbers on your bays. You can assign Parkers to specific bays, and in turn they can earn money when another Parker uses their bay.",
    bay_settings_num_of_bays:"Number of Bays",
    create_car_park:"Create carpark",
    bonus_bay_settings:"Bonus bay settings",
    priority_allocation:"Priority allocation",
    bonus_bay_over_casual_bay:"Bonus bays get priority over casual bays",
    monthly: `Monthly`,
    weekly: `Weekly`,
    termLength: `Term length`,
    create_group_name_help:"Do you have a nickname for this group? You can change it here.",
    create_group_desc_help:"Include additional information to help you recognise between groups",
    use_carpark_settings:"Use carpark settings",
    use_custom_settings:"Use custom settings",
    customise_group_type:"Customise group type",
    create_group:"Create Group",
    group_name:"Group Name",
    error_group_name_required:"Group name required!",
    error_name_required:"Carpark name required!",
    error_address_required:"Carpark address required!",
    error_numberOfBays_required:"Number of bays required!",
    error_price_per_week_or_month_required: "Price per week or month required!",
    error_pricePerMonth_required:"Price per month required!",
    error_pricePerWeek_required:"Price per week required!",
    error_pricePerHour_required:"Price per hour required!",
    error_pricePerDay_required:"Price per day required!",
    refundSubscription: `Refund subscription (admin only)`,
    refundSubscriptionQuestion: `Refund subscription?`,
    refunding_subscription: `Refunding subscription...`,
    subscription_refunded: `Subscription refunded!`,
    unable_to_refund_subscription: `Unable to refund subscription`,
    invalidBankAccountNumber: `Invalid Bank Account Number`,
    extraParkingInstructions:"Parking instructions",
    extraParkingInsHelp:"Instructions are visible after parking or subscription started",
    parkableMessages: `Parkable messages`,
    sendMessage: `Send message`,
    parkable: `Parkable`,
    stripe_platform_customer_id: `Stripe platform customer id`,
    lostDeposits: `Lost Deposits`,
    setting_park_segment: `Setting park segment`,
    park_segment_updated: `Park segment updated`,
    choose_segment_for_park: `Choose segment for park`,
    load_ended_leases: `Load ended leases`,
    parker: `Parker`,
    term: `Term`,
    arrival_date: `Arrival date`,
    show_transactions: `Show transactions`,
    transactions: `Transactions`,
    load_parks: `Load parks`,
    selected_transaction: `Selected transaction`,
    territories: `Territories`,
    selected_territory: `Selected territory`,
    show_territories: `Show territories`,
    payouts: `Payouts`,
    show_payouts: `Show payouts`,
    load_more_payouts: `Load more payouts`,
    selected_payout: `Selected payout`,
    payments: `Payments`,
    links: `Links`,
    organisation: `Organisation`,
    loading_transactions: `Loading transactions...`,
    stop_loading: `Stop loading`,
    margin: `Margin`,
    show_only_active_sessions: `Show only active sessions`,
    bay_allocation: "Bay allocation",
    createASessionForUserNoSignage: `Start casual session`,
    end_parking_session: `End parking session`,
    approvedBy: `Approved by`,
    charge_user_question:`Charge User?`,
    approve_charge_question: `Approve charge?`,
    approve_charge: `Approve charge`,
    unable_to_charge_user_does_not_have_a_registered_card: `Unable to create a charge, user doesn't have a registered card.`,
    unable_to_charge_user: `Unable to charge user. Please try again.`,
    retry_charge: `Retry charge`,
    retry_charge_question: `Retry charge?`,
    delete_charge: `Delete charge`,
    delete_charge_question: `Delete charge?`,
    creating_charge: `Creating charge`,
    charge_created: `Charge created`,
    approving_charge: `Approving charge`,
    charge_approved: `Charge approved`,
    retrying_charge: `Retrying charge`,
    deleting_charge: `Deleting charge`,
    charge_deleted: `Charge deleted`,
    awaiting_approval: `Awaiting approval`,
    approve: `Approve`,
    searchCharges: `Search charges`,
    miscellaneousCharges: `Miscellaneous Charges`,
    charges_over_requires_approval: `** For charges over $100.00, approval of another administrator is required.`,
    load_active_sessions: `Load active sessions`,
    charge_details: `Charge details`,
    organisations: `Organisations`,
    subscription_ended_after_a_failed_payment: `Subscription ended after a failed payment`,
    load_deposits: `Load deposits`,
    create_gate: "Create Gate",
    gsm_gate_opener: "GSM Gate Opener",
    iot_gate_opener: "IOT Gate Opener",
    gate_name:"Name",
    gate_name_hint:"Name for the controller",
    gate_password:"Password",
    gate_new_password:"New Password",
    gate_gsm_number:"Mobile Number",
    gate_blue_thooth_id:"Bluethooth Id",
    major:"Major",
    minor:"Minor",
    unable_to_create_access_gates:"Unable to create Access Gate",
    unable_to_delete_access_gates:"Unable to delete Access Gate",
    gate_gsm_number_hint:"Phone Number (eg. +64 21 1234567)",
    gate_delete_gate: "Delete Access Control?",
    gate_configure: "Configure Access Control",
    gate_all_can_control: "Allow all numbers can call in to control:",
    gate_no_reply_sms: "No need confirmation SMS when the relay is ON/OFF:",
    gate_relay_control: "Relay Control",
    gate_disableConfirmationSms : "No need confirmation Send",
    gate_disableConfirmationSms_fail : "No need confirmation Send failed",
    gate_allowAllNumbersToCall : "Allow all numbers can call Send",
    gate_allowAllNumbersToCall_fail : "Allow all numbers can call Send failed",
    opening : "Opening ...",
    classic_view: `Classic view`,
    pfb_view: `PfB view`,
    this_payment_has_an_existing_refund: `This payment has an existing refund`,
    filter_bay_signage: `Filter bay signage`,
    filter_vehicles: `Filter vehicles`,
    search_all_vehicles: `Search all vehicles`,
    valid_vehicles_in_this_park: `Valid vehicles in this park`,
    a_parker_can_have_multiple_vehicles: `A parker can have multiple vehicles`,
    no_vehicles: `No vehicles`,
    registration_search: `Registration search (case insensitive):`,
    blocked_vehicles: `Blocked vehicles`,
    get_blocked_vehicles: `Get blocked vehicles`,
    sub: `Sub`,
    termsAndCond_p1:`This agreement ("Agreement") governs the disclosure of information in this data room to you for the purpose of pursuing an investment ("Purpose") in Parkable Holdings Limited ("Company", "we" or "us").
     This Agreement shall be governed by the laws of New Zealand. `,
     termsAndCond_p2:`All information contained in this data room is confidential ("Confidential Information").`,
     termsAndCond_p3:`You acknowledge and agree to hold in strict confidence and not disclose to any third party any Confidential Information except as approved in writing in advance by the Company, and will use the Confidential Information for no purpose other than the Purpose.     `,
     termsAndCond_p4:`You will take normal and reasonable precautions to protect the Confidential Information so that you do not divulge Confidential Information to any third party. Upon the Company's request at any time, you shall promptly destroy or return all Confidential Information, including all copies thereof. If any notes, analyses, compilations, studies, interpretations, documents or records prepared by you contain any such Confidential Information, all such Confidential Information will be and remain our property.     `,
     termsAndCond_p5:`The term Confidential Information shall not include information which is or becomes generally available to the public other than as a result of an unauthorized disclosure by you.     `,
     termsAndCond_p6:`You agree that any breach or threatened breach of this Agreement may cause irreparable harm to us, for which we shall, in addition to any other legal or equitable remedies, be entitled to seek an injunction or similar equitable relief against any such breach or threatened breach.`,
     termsAndCond_p7:`If the Confidential Information is protected under the terms of a separate confidentiality agreement between you/your company and us, then this Agreement does not supersede or replace any terms of such confidentiality agreement.`,
     termsAndCond_heading:'Terms and Privacy Policy',
     accept_tc:"I have read and agree",
}

function parse(num: number, template: any) {
    const reg = /\(\{([^)]+)\}\)/gm.exec(template);
    if (!reg) {
        return '';
    }
    let str = reg[1];

    let expression = new RegExp(`=${num}\\s*\\{(.*?)\\}`, "g").exec(str);
    let expressionOther = new RegExp(`other\\s*\\{(.*?)\\}`, "g").exec(str);

    if (expression !== null) {
        return expression[1];
    } else if (expressionOther !== null) {
        // @ts-ignore
        return expressionOther[1].replace("#", num);
    }
    return "";
}
