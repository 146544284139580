import axios from "axios";
import { getApi } from "./environment";
import { firebaseService } from "../util/firebase.service";
//import {Api} from "src/config/api.config";
//import {Cookie} from "src/constants/http";
//import {firebaseService} from "src/services/firebase.service";
//import {retrieveCookies} from "src/util/cookies.util";
//import DEFAULT_API_VERSION from "./apiVersion";

// Global http config goes here
const http = axios.create({})
//const NETWORK_AUTHENTICATION_REQUIRED = 511;

// Base configuration
http.interceptors.request.use(async config => {

    const token = await firebaseService.getAuthToken();

    config.headers = {
        //"X-Parkable-API-Version": DEFAULT_API_VERSION,
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        "Authorization": token??'',
            //?? retrieveCookies()?.[Cookie.Token]
        //    ?? "",
        //'X-Token': token ?? '',
        ...config.headers // Allow overriding
    }
    config.baseURL = getApi();
    return config;
})

async function tryRequest<T>(req: () => Promise<T>, retries = 1) : Promise<T> {
    try {
        return await req();
    }
    catch(err: any) {
        console.log("ERROR: ", err?.response);
        /*if(err?.response?.status === 401 && err?.response?.data?.errorCode === "INVALID_TOKEN") {
            if(retries > 0) {
                return tryRequest(req, retries - 1);
            }
            firebaseService.logoutUser().catch(console.log);
        } else if(err?.response?.status === NETWORK_AUTHENTICATION_REQUIRED){
            console.log(`ERROR will logout user`);
            firebaseService.logoutUser().catch(console.log);
        }*/
        throw err;
    }
}

export const get = async <T = any>(
    url: string,
    params?: any,
    headers?: any
) => {
    const response = await tryRequest(() => http.get<T>(
        url,
        {
            params: params ? {...params} : undefined,
            headers: {...headers},
        }));
    return response.data;
}

export const getMany = async <T = any>(
    urls: string[],
    params?: any,
    headers?: any
) => {
    const dataList = await Promise.all(urls.map(async url => {
        return (await http.get<T>(
            url,
            {
                params: params ? { ...params } : undefined,
                headers: { ...headers },
            })).data;
    }))
    return dataList;
}

export const post = async <T = any>(url: string,
                                    body?: any,
                                    headers?: any) => {
    const response = await tryRequest(() => http.post<T>(
        url,
        body,//removed {...data} as it stripped the type and axios didnt handle form data
        {headers: headers}));
    return response.data;
}

export const patch = async <T = any>(
    url: string,
    body?: any,
    headers?: any
) => {
    const response = await tryRequest(() => http.patch<T>(
        url,
        {...body},
        {headers: {...headers}}));
    return response.data;
};

export const put = async <T = any>(
    url: string,
    body?: any,
    headers?: any
) => {
    const response = await tryRequest(() => http.put<T>(
        url,
        {...body},
        {headers: {...headers}}));
    return response.data;
};

export const del = async <T = any>(
    url: string,
    headers?: any
) => {
    const response = await tryRequest(() => http.delete<T>(
        url,
        {headers: {...headers}}));
    return response.data;
};

export const fetcher = async (key: string, paramsStr: string | undefined) => {
    const params = JSON.parse(paramsStr ?? '{}');
    return get(key, params);
}
