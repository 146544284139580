import * as Type from '../actions/user'

export type SessionReducer = {
    loginFailed: boolean
}

export default function authReducer(state = {} as SessionReducer, action: any) {
    switch (action.type) {
        case Type.USER_LOGGED_OUT: {
            return {
                ...state,
                loginFailed: false
            };
        }
        case Type.USER_LOGIN_FAILED: {

            return {
                ...state,
                loginFailed: true
            };
        }

        default:
            return state;

    }
}
