import {combineReducers} from 'redux';
import { routerReducer} from 'react-router-redux'
import { reducer as formReducer } from 'redux-form'
import userReducer, { UserReducer } from './user'
import authReducer, { SessionReducer } from './auth'
import restReducer from './rest'

export type NotificationsReducer = {
    notifications: any[]
}

export type RestReducer = {
    loading: any,
}

export type FormReducer = {

}

export type MainReducer = {
    routing: any,
    users: UserReducer,
    form: FormReducer,
    rest: RestReducer,
    session: SessionReducer,
    notifications: NotificationsReducer
    password: any,
} | undefined;

const appReducer = combineReducers({
    routing: routerReducer,
    session: authReducer,
    users: userReducer,
    form: formReducer,
    rest: restReducer,
})

export default function rootReducer(state: any, action: any) {
    //console.log("ROOT REDUCER - rootReducer")
    if (action.type === 'USER_LOGGED_OUT') {
        state = undefined
    }

    return appReducer(state, action)
}
