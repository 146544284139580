import { useMySWR } from "../../util/useMySWR";
import { get } from "../fetcher";
import { firebaseService } from "../../util/firebase.service";
import { User } from "../../redux/reducers/user";

type CurrentUserResponse = { user: User };

export const useCurrentUser = () => {
    return useMySWR<CurrentUserResponse>(`v2/users`);
}

export const getCurrentUser = async () => {
    return get<CurrentUserResponse>(`v2/users`);
}

export const useUserRefresh = () => {
    const token = firebaseService.syncToken;
    return useMySWR<any>(token ? `/v2/users/auth/token?token=${token}&deviceToken=web&platform=dataroom&version=3.0` : null);
}
