import React from 'react'
import {Panel} from 'react-bootstrap'
import _ from 'lodash'
import sections from '../../constants/sections'
import  "./DataRoom.css"
import { DataroomDoc, dataroomRead, useDataroomDocs } from "../../api/dataroom/dataroom.api";
import { useCurrentUser } from "../../api/user/user.api";

const DataRoom: React.FC = () => {

    const {user} = useCurrentUser();
    const {dataroomDocs} = useDataroomDocs();

    const onClick = async (doc: DataroomDoc) => {
        if (!user) {return}
        try {
            await dataroomRead(user.id, `${doc.major}.${doc.minor} ${doc.title}`);
        } catch (e) {
            console.log('error on documentRead');
        }
    };

    const renderDoc = (doc: DataroomDoc, i: number) => (
        <div key={i} className={"minor"}>
            {!!doc.link ?
                <a onClick={() => onClick(doc)} href={doc.link} target='_blank' rel="noopener noreferrer">
                    {doc.title}
                </a> :
                <div className={'inline'}>{doc.title}</div>}
        </div>);

    const renderTable = () => _.map(sections, ({major, title , minors}, k) => (
            <div key={k} className={"major-container"}>
                <div className={"major"}>{`${major}. ${title}`}</div>

                {_.map(minors, ({minor, title, subVersions}, i) => (
                    <div key={i} className={"minor-container"}>
                        <div className={"minor-title"}>
                            {`${major}.${minor} ${title}`}
                        </div>
                        {_.chain(dataroomDocs??[])
                            .filter(p => p.major === major && p.minor === minor && !p.subVersion)
                            .orderBy(['title'])
                            .map(renderDoc)
                            .value()
                        }
                        {(subVersions || []).map(({subVersion, title}, j) => (
                            <div key={j} className={"subVersion-container"}>
                                <div className={"minor-title"}>
                                    {`${major}.${minor}.${subVersion} ${title}`}
                                </div>
                                {_.chain(dataroomDocs??[])
                                    .filter(p => p.major === major && p.minor === minor && p.subVersion === subVersion)
                                    .orderBy(['title'])
                                    .map(renderDoc)
                                    .value()
                                }
                            </div>
                        ))}
                    </div>))}
            </div>));

    return (
        <Panel>
            <div className={"data-room-intro"}>
                <h3>Welcome to the Parkable data room</h3>
            </div>
            <Panel.Body>
                {renderTable()}
            </Panel.Body>
        </Panel>)
}

export default DataRoom;
