import React, { useState } from 'react'
import { Button} from 'react-bootstrap'
import Spinner from '../common/spinnerSmall'
import logo from '../../resources/images/parkable-logo-white.png'
import {english} from "../Strings";
import { useUserRoles } from "../../api/userRole/userRole.api";
import { User } from "../../redux/reducers/user";
import { isNotAuthorized } from "../../util/userRolesCheck";

type LoginProps = {
    doLogin: (username: string, password: string) => void,
    doLogout: () => void,
    tokenLogin: any,
    error: any,
    loginFailed: boolean,
    loading: any,
    user: User | undefined,
}

type Props = LoginProps;

const Login: React.FC<Props> = (props) => {

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const {user} = props;
    const {userRoles} = useUserRoles();
    const loggedInButNotAuthorized = !!user && !!userRoles && isNotAuthorized(userRoles);

    const logout = () => {
        props.doLogout();
    }

    const submit = (e: any) => {
        if (e) {
            e.preventDefault();
        }

        props.doLogin(username, password);
    }

    const keyDown = (e: any) => {
        if (e.keyCode === 13) {
            submit(e)
        }
    }

    if (props.tokenLogin) {
        return <div style={{width: '100%', height: '300px'}}>
            <h4 style={{textAlign: 'center', marginTop: '150px'}}>Authenticating</h4>
            <div style={{margin: 'auto', width: '30px'}}><Spinner on={true} /></div>
        </div>
    }

    return (<div id='loginWidth' >
        <div className='loginForm' onKeyDown={keyDown}>

            <img className='center' src={logo} alt='logo'/>

            <div className='loginBox'>

                {loggedInButNotAuthorized ? <div>

                    <div style={{textAlign: 'center'}}>Sorry, you do not have permission to access this service</div>
                    <div style={{textAlign: 'center'}}>Please contact Parkable for assistance</div>

                    <Button className="standard center" style={{outline:'none', marginTop: 20}} onClick={logout} >
                        Log out
                    </Button>

                </div> : <>

                    <div>
                        <h2>Welcome!</h2>
                        <div style={{color: '#66666'}}>Enter your login details below</div>
                    </div>

                    {!!props.error && <div className='row centered errors'>{props.error}</div>}

                    <div className='loginField'>
                        <h3 className='login'>Username/Email</h3>
                        <input className='login'
                            id='username'
                            type='text'
                            autoCapitalize="off"
                            autoCorrect="off"
                            placeholder={english.eg_my_username} value={username} onChange={(e) => setUsername(e.target.value)}/>
                    </div>
                    <div className='loginField'>
                        <h3 className='login'>Password</h3>
                        <input className='login' id='password' type='password'
                               value={password}
                               onChange={(e) => setPassword(e.target.value)}
                        />
                    </div>
                    <div>
                        <div className='center' style={{marginTop: '20px'}}>

                            <div className='loginfailed'>
                                {props.loginFailed && !props.loading ? 'Login Failed': ''}
                            </div>

                            <Button className="standard center" onClick={submit} style={{outline:'none'}}>
                                Login
                            </Button>
                            <br/>
                            <div className='center' style={{width: '30px'}}>
                                <Spinner on={props.loading} />
                            </div>
                        </div>
                    </div>
                </>}

            </div>
        </div>
    </div>)

}

export default Login;
