import { environment } from "../api/environment";

export const firebaseClientConfig = () => {
    if (environment() === 'production') {
        return {
            apiKey: "AIzaSyCJNgdXvPIDMe1W96WyZy8_6mFn56B8wOg",
            authDomain: "parkable-app.firebaseapp.com",
            databaseURL: "https://parkable-app.firebaseio.com",
            projectId: "parkable-app",
            storageBucket: "parkable-app.appspot.com",
            messagingSenderId: "1047279485966",
            appId: "1:1047279485966:web:2c77024191097fd78d285f",
            measurementId: "G-B2H70VRT5F"
        };
    } else if (environment() === 'staging') {
        return {
            apiKey: "AIzaSyDWkx_JxlFd0sIlJHR5hiszDG_fHFWLgwk",
            authDomain: "parkable-staging.firebaseapp.com",
            databaseURL: "https://parkable-staging.firebaseio.com",
            projectId: "parkable-staging",
            storageBucket: "parkable-staging.appspot.com",
            messagingSenderId: "394073742718",
            appId: "1:394073742718:web:b86182e6ec15625e8ba72f",
            measurementId: "G-BZPYNDWQYW"
        };
    } /*else if (environment() === 'qa') {
        return  {
            apiKey: "AIzaSyBlqvH1TZGy5OxoISBWqpUtJRdcHbdGfbU",
            authDomain: "parkable-qa.firebaseapp.com",
            databaseURL: "https://parkable-qa.firebaseio.com",
            projectId: "parkable-qa",
            storageBucket: "parkable-qa.appspot.com",
            messagingSenderId: "106705050091",
            appId: "1:106705050091:web:99f44f17c2770c5e65a1ae",

            measurementId: "G-X7QP7RC325"//??
        };
    }*/

    return { //dev and local default
        apiKey: "AIzaSyDeMUptJEWKuzf1vbZv6IrrAQ5yjcitbqs",
        authDomain: "parkable-dev.firebaseapp.com",
        databaseURL: "https://parkable-dev.firebaseio.com",
        projectId: "parkable-dev",
        storageBucket: "parkable-dev.appspot.com",
        messagingSenderId: "1068172338961",
        appId: "1:1068172338961:web:798669bc2f595705de4eb0",
        measurementId: undefined as undefined | string
    };
}
