import * as React from 'react';
import {connect} from 'react-redux';
import {Grid, Row, Col} from 'react-bootstrap';
import NavPanel from './components/navpanel/navpanel';
import WebsiteFooter from './components/websitefooter';
import {english} from "./components/Strings";
import {logout} from './redux/actions/user';
// @ts-ignore
import {AlertList} from 'react-bs-notifier'
import "./App.css";
import type {User} from './redux/actions/user';
import ParkableLogo from "./resources/images/parkable-logo-dark.svg";
import { MainReducer } from "./redux/reducers/root";
import { isAuthorized } from "./util/userRolesCheck";

type AppProps = {
    children?: React.ReactNode,
    authorized: boolean,
    anyOrganisationMember: boolean,
    notifications?: Array<any>,
    dispatch: Function,
    user?: User,
    loggedInUser?: User,
    userDetails?: Object,
    routeParams: Object
}

type AppState = {
    hiddenMenu: boolean
}

const getReduxProps = (state: MainReducer) => {
    const user = state?.users?.user;
    const userRoles = state?.users?.userRoles;
    return {
        authorized: !!user && !!userRoles && isAuthorized(userRoles),
        notifications: state?.notifications?.notifications,
    }
};

type Props = AppProps & ReturnType<typeof getReduxProps>;

class App extends React.Component<Props, AppState> {

    constructor(props: Props){
        super(props);

        this.state = { hiddenMenu: true }
    }

    doLogout = () => {
        console.log("logout");
        this.props.dispatch(logout());
    };

    onAlertDismissed = (alert: any) => {
        // this.props.dispatch(removeNotification(alert));
    };

    render() {

        const showNavPanel = this.props.authorized;

        return (
            <div className="App">
                <AlertList
                    timeout={4000}
                    alerts={(this.props.notifications || [])}
                    onDismiss={this.onAlertDismissed}
                />
                <Grid fluid className="mainPanel">
                    <Row className="equal">

                    {showNavPanel &&
                        <Col className="admin-nav-panel" xsHidden={this.state.hiddenMenu} sm={2} md={2} lg={2} >
                            <div className='navpan'>
                                <img src={ParkableLogo} alt='logo' className="parkableLogoBlue"/>
                                <button className='simple-blue btn-show-menu' onClick={() => this.setState({hiddenMenu: !this.state.hiddenMenu})}>{english.hideMenu}</button>
                                <NavPanel
                                    hideMenu={() => this.setState({hiddenMenu: true})}
                                />
                                <div className="logout-button">
                                    <a onClick={this.doLogout}>{english.log_out}</a> {/* eslint-disable-line */}
                                </div>
                            </div>
                        </Col>}
                        <Col className="main-content" style={{background: '#D8E1E7', padding:'0px'}} xs={12} sm={showNavPanel ? 10 : 12} md={showNavPanel ? 10 : 12} lg={showNavPanel ? 10 : 12} >
                            <div className="wrapper-div">
                                {showNavPanel && <button className='simple-blue btn-show-menu' style={{ marginTop: '15px', marginBottom: '-15px', color: ' #1F2034' }} onClick={() => this.setState({hiddenMenu: !this.state.hiddenMenu})}>{english.showMenu}</button>}
                                {this.props.children}
                            </div>
                        </Col>
                    </Row>

                </Grid>

                <WebsiteFooter/>

            </div>
        );
    }
}

const _App = connect(getReduxProps)(App);

//polyfills
if (!String.prototype.endsWith) {
    // eslint-disable-next-line no-extend-native
    String.prototype.endsWith = function(search, this_len) {
        if (this_len === undefined || this_len > this.length) {
            this_len = this.length;
        }
        return this.substring(this_len - search.length, this_len) === search;
    };
}

export default _App
