import * as Type from '../actions/user'
import { UserRoleDTO } from "../../api/userRole/dto/UserRole.dto";

export type User = {id: number}

export type UserReducer = {
    loading: boolean,
    tokenLogin?: boolean,
    authError?: any,
    user: User | undefined,
    userRoles?: undefined | UserRoleDTO[],
}

export default function userReducer(state = {
    loading: false,
    user: undefined,
}, action: any) {

    switch (action.type) {
        case Type.USER_ROLES_RECEIVED: {
            return {
                ...state,
                userRoles: action.userRoles
            }
        }

        case Type.USER_REQUEST_PENDING:
            return {
                ...state,
                loading: true,
                tokenLogin: false
            };

        case Type.USER_REQUEST_TOKEN_LOGIN_PENDING:
            return {
                ...state,
                loading: true,
                tokenLogin: true
            }

        case Type.USER_AUTH_ERROR:
            console.log("USER_AUTH_ERROR");
            return {
                ...state,
                loading: false,
                tokenLogin: false,
                authError: action.message
            }

        case Type.USER_RESPONSE_RECEIVED:
            return {
                ...state,
                loading: false,
                tokenLogin: false
            };

        case Type.USER_RECEIVED: {
            return {
                ...state,
                user: action.user,
            };
        }

        default:
            return state;

    }
}
