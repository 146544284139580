import { get } from "../api/fetcher";
import useSWR, {} from "swr";
// @ts-ignore
import {ConfigInterface, fetcherFn, keyInterface, keyType} from "swr/dist/types";

export const useMySWR = <Data> (key: keyInterface, fn: fetcherFn<Data> = get, config?: ConfigInterface<Data, Error>) => {
    const {data, ...rest} = useSWR<Data>(key, fn, config);
    const loading = !!key && data === undefined;

    return {
        ...(data??({} as Partial<Data>)),
        ...rest,
        loading,
        key
    }
}

export type KeyLoader<Data> = (index: number, previousPageData: Data | null) => keyType

