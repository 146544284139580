export const REQUEST_PENDING = 'REST_REQUEST_PENDING';
export const RESPONSE_RECEIVED = 'REST_RESPONSE_RECEIVED';
export const ERROR_RECEIVED = 'ERROR_RECEIVED';
export const CLEAR_REQUEST_STATE = 'CLEAR_REQUEST_STATE';

export function requestPending() {
    return {
        type: REQUEST_PENDING
    }
}

export function responseReceived(message?: any) {
    return {
        type: RESPONSE_RECEIVED,
        success: {
            message
        }
    }
}

export function errorReceived(error: any) {
    return {
        type: ERROR_RECEIVED,
        error
    }
}

export function clearRequestState() {
    return {
        type: CLEAR_REQUEST_STATE
    }
}
