import { UserRoleDTO } from 'src/api/userRole/dto/UserRole.dto';
import { Nully } from 'src/util/nully';

export const ADMINISTRATOR = "ADMINISTRATOR";
export const INVESTOR = "INVESTOR";
export const INVESTOR_PENDING = "INVESTOR_PENDING";
export const SUPER_INVESTOR = "SUPER_INVESTOR";
export const SUPER_INVESTOR_PENDING = "SUPER_INVESTOR_PENDING";

export const isAdmin = (userRoles: Nully<UserRoleDTO[]>) => {
    return !!userRoles
        ?.map(ur => ur?.role?.name)
        ?.find(roleName => roleName === ADMINISTRATOR)
}

export const isInvestor = (userRoles: Nully<UserRoleDTO[]>) => {
    return !!userRoles
        ?.map(ur => ur?.role?.name)
        ?.find(roleName => roleName === INVESTOR)
}

export const isSuperInvestor = (userRoles: Nully<UserRoleDTO[]>) => {
    return !!userRoles
        ?.map(ur => ur?.role?.name)
        ?.find(roleName => roleName === SUPER_INVESTOR)
}

export const isPendingInvestor = (userRoles: Nully<UserRoleDTO[]>) => {
    return !!userRoles
        ?.map(ur => ur?.role?.name)
        ?.find(roleName => roleName === SUPER_INVESTOR_PENDING || roleName === INVESTOR_PENDING)
}

export const isAuthorized = (userRoles: Nully<UserRoleDTO[]>) => {
    return isInvestor(userRoles) || isSuperInvestor(userRoles) || isAdmin(userRoles) || isPendingInvestor(userRoles)
}

export const isNotAuthorized = (userRoles: Nully<UserRoleDTO[]>) => {
    return !isAuthorized(userRoles)
}

export const userNeedToAcceptTAndC = (userRoles: Nully<UserRoleDTO[]>) =>{
    return isPendingInvestor(userRoles);
}
