import { useMySWR } from "../../util/useMySWR";
import { post, put } from "../fetcher";
import { useUserRoles } from "../userRole/userRole.api";

export type DataroomDoc = {
    title: string;
    major: string;
    minor: string;
    subVersion: string;
    link: string;
    restrict: string;
}

export type DataroomDocsResponse = {
    dataroomDocs: DataroomDoc[]
}

export const useDataroomDocs = () => {

    const {isAdmin, isSuperInvestor, isInvestor} = useUserRoles();

    let url: string | null = null;
    if (isAdmin || isSuperInvestor) {
        url = '/v1/dataroom';
    } else if (isInvestor) {
        url = '/v1/dataroom/restricted';
    }

    return useMySWR<DataroomDocsResponse>(url);
}

export const dataroomRead = (userId: number, comments: any) => post(`/v2/useraction?userId=${userId}`, {
    action: 'DataroomDocumentRead',
    comments
});

export const acceptTandC = (data?: any) => {
    return put( `/v1/dataroom/acceptTermsAndConditions`, data);
}
