import {useMySWR} from "src/util/useMySWR";
import {Nully} from "src/util/nully";
import { RetrieveUserRolesResponse } from "./dto/RetrieveUserRolesResponse";
import { get } from "../fetcher";
import { encodeQueryParams } from "../../util/encodeQueryParams";
import { useCurrentUser } from "../user/user.api";

export const useUserRoles = () => {
    const {user} = useCurrentUser();
    const p = {} as any;
    if (!!user?.id) {
        p.userId = user?.id;
    }
    const params = encodeQueryParams(p);
    const hasParams = params.length > 0;
    const resp = useMySWR<RetrieveUserRolesResponse>(!!user?.id ? `/v4/users/roles` + (hasParams ? `?${params}` : ``) : null);

    const {userRoles} = resp;

    const isAdmin = !!userRoles?.find(ur => ur.role?.name === 'ADMINISTRATOR' && !ur.suspended);
    const isSuperInvestor = !!userRoles?.find(ur => ur.role?.name === 'SUPER_INVESTOR' && !ur.suspended);
    const isInvestor = !!userRoles?.find(ur => ur.role?.name === 'INVESTOR' && !ur.suspended);

    //console.log("!!!user deets", isAdmin, isSuperInvestor, isInvestor);

    return {
        ...resp,
        isAdmin,
        isSuperInvestor,
        isInvestor
    }
};

let userRolesPromise: Promise<RetrieveUserRolesResponse> | null = null;

export const getUserRoles = (userId: Nully<number>): Promise<Nully<RetrieveUserRolesResponse>> => {
    if (!userId) {
        return Promise.resolve(null);
    }

    if (userRolesPromise !== null) {
        return userRolesPromise;
    }

    userRolesPromise = get<RetrieveUserRolesResponse>(`/v4/users/roles`).then(resp => {
        userRolesPromise = null;
        return resp;
    });

    return userRolesPromise;
}
