let API = 'https://parkable-dev.appspot.com/api/';

let server = (localStorage.getItem('which_server'));

if ((process.env.REACT_APP_ENV === 'production') || (server === 'production')) {
    API = 'https://parkable-app.appspot.com/api/';
}
else if ((process.env.REACT_APP_ENV === 'staging') || (server === 'staging')) {
    API = 'https://parkable-staging.appspot.com/api/';
}
else if ((process.env.REACT_APP_ENV === 'local') || (server === 'local')) {
    API = 'http://localhost:8080/api/'
}

export const environment = () => {
    if ((process.env.REACT_APP_ENV === 'production') || (server === 'production')) {
        return 'production'
    } else if ((process.env.REACT_APP_ENV === 'staging') || (server === 'staging')) {
        return 'staging'
    } else if ((process.env.REACT_APP_ENV === 'local') || (server === 'local')) {
        return 'local'
    } else {
        return 'dev'
    }
};

export const getApi = () => API;
